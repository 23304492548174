@import '../../global.scss';

.contact {
	background-color: lightblue;

	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		width: 50%;
		padding: 70px;
		border-radius: 100%;
		box-shadow: 20px 25px rgb(194, 17, 52);
		padding-left: 80px;
		padding-right: 80px;
		height: 575px;
		margin: 50px 400px;
		background-color: #ffffff;
		line-height: 2;

		@include mobile {
			flex-direction: column;
			margin: 20px 0;
			width: 80%;
			height: 70%;
			padding: 45px 35px;
			box-shadow: none;
		}

		h1 {
			font-size: 45px;

			@include mobile {
				font-size: 25px;
			}
		}

		p {
			font-size: 19px;
			font-weight: bold;

			@include mobile {
				font-size: 15px;
			}
		}
	}
}
