@import '../../global.scss';

.intro {
	background-color: white;
	display: flex;

	@include mobile {
		flex-direction: column;
		align-items: center;
	}

	.left {
		flex: 0.5;
		overflow: hidden;

		.imgContainer {
			width: 700px;
			height: 750px;
			background-color: crimson;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			float: right;

			@include mobile {
				align-items: flex-start;
				width: 100vh;
			}

			img {
				height: 100%;
				width: 50%;
				border-radius: 25%;

				@include mobile {
					height: 50%;
					width: 25%;
				}
			}
		}
	}

	.right {
		flex: 0.88;
		position: relative;

		.wrapper {
			width: 100%;
			height: 80%;
			padding-left: 50px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include mobile {
				padding-left: 0;
				align-items: center;
			}

			h1 {
				font-size: 60px;
				margin: 10px 0;

				@include mobile {
					font-size: 40px;
				}
			}
			h2 {
				font-size: 35px;
			}
			h3 {
				font-size: 30px;
				// margin: 10px 0;

				@include mobile {
					font-size: 20px;
				}
			}
			span {
				font-size: 30px;
				font-weight: 500;
				color: crimson;

				@include mobile {
					font-size: 20px;
				}
			}

			.ityped-cursor {
				animation: blink 1s infinite;
			}

			@keyframes blink {
				50% {
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}
		}
		a {
			position: absolute;
			bottom: 10px;
			left: 20%;
		}
		img {
			width: 50px;
			animation: arrowBlink 2s infinite;
		}

		@keyframes arrowBlink {
			100% {
				opacity: 0;
			}
		}
	}
}
