@import '../../global.scss';

.portfolio {
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		font-size: 50px;

		@include mobile {
			font-size: 25px;
		}
	}

	ul {
		margin: 60px;
		padding: 0;
		list-style: none;
		display: flex;

		@include mobile {
			margin: 10px 0;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.container {
		width: 70%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		@include mobile {
			width: 100%;
			flex-direction: column;
		}

		.item {
			width: 250px;
			height: 150px;
			border-radius: 20px;
			border: 1px solid lightgrey;
			margin: 10px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			position: relative;

			@include mobile {
				width: 130px;
				height: 100px;
			}

			h3 {
				position: absolute;
				font-size: 20px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 1;
			}

			&:hover {
				background-color: $mainColor;
				img {
					opacity: 0.2;
					z-index: 0;
				}
			}
		}
	}
}
