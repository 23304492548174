.app {
	height: 100vh;

	.sections {
		width: 100%;
		height: calc(100vh - 70px);
		background-color: lightcoral;
		position: relative;
		top: 70px;
		scroll-behavior: smooth;
		scroll-snap-type: y mandatory;
		scrollbar-width: none; // For firefox
		&::-webkit-scrollbar {
			// For other browsers
			display: none;
		}

		> * {
			width: 100vw;
			height: calc(100vh - 70px);
			scroll-snap-align: start;
		}
	}
}
