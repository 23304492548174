@import '../../global.scss';

.portfolioList {
	font-size: 14px;
	margin-right: 10px;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;

	@include mobile {
		margin-right: 2px;
	}

	&.active {
		background-color: $mainColor;
		color: white;
	}
}
